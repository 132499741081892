import React, {useState, useEffect, useRef} from "react";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { Questionnaire, ResponseResult, Question, RouteParam } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import '../../styles/form.scss';
import { Input, Checkbox, Select, Radio, InputNumber, InputDate, Lecture } from '../../components/FormComponents';
import { useParams } from 'react-router-dom';
import Logo1 from '../../assets/logo_gobierno.png';
import Logo2 from '../../assets/logo_pec.jpg';
import ModalError from "../../components/ModalError";
import SignatureCanvas from 'react-signature-canvas'

export const QuestionnaireAnswer = () => {

    const [userData, setData]:any = useState({});
    const [formData, setFormData] = useState<Partial<Questionnaire>>({});
    const [finalData, setFinalData]:any = useState({});
    const [currentSection, setCurrentSection] = useState(0);
    const [validated, setValidated] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [redirectTo, setRedirect] = useState(-2);
    const [showLastStep, setLastStep] = useState(false);
    const [sectionHistory, setSectionHistory] = useState<number[]>([]);
    const { slug } = useParams<RouteParam>();
    const [firstStep, setFirstStep] = useState(true);
    const [student, setStudent] = useState({
        _id: ''
    });
    const [ modalContent, setModalContent ] = useState({
        error: '',
        body: '',
        title: '',
        show: false
    });
    let sigCanvas:any = useRef(null);

    useEffect(()=>{
        const params = new URLSearchParams(window.location.search);
        if( params.has('token') ){
            const token = params.get('token') || '';
            getStudent( token );
            let storagedData = localStorage.data ? JSON.parse(localStorage.data) : {};
            if(storagedData[currentSection]){
                setData({...storagedData[currentSection]})
            }
        }else{
            Swal.fire("Cuestionario", "Error al cargar la información de la sesión. Es necesario ingresar los datos nuevamente.", "error")
            .then(()=>{
                window.location.href="/";
            });
        }
    },[]);

    const getStudent = async (token:string) => {
        try{
            Swal.fire("Cuestionario", "Cargando...");
            Swal.showLoading();
            const result = await request("/api/session/getToken", {
                headers: {
                    'Authorization': `Bearer ${ token }`
                }
            });
            Swal.close()
            if(result.data){
                getQuestionnaire();
            }else{
                Swal.fire("Cuestionario", "Error al cargar la información de la sesión. Es necesario ingresar los datos nuevamente.", "error")
                .then(()=>{
                    window.location.href="/";
                });
            }
        }catch(e:unknown){

            Swal.fire("Cuestionario", "Error al cargar", "error");
        }
    }

    const getQuestionnaire = async () => {
        try{
            Swal.fire("Cuestionario", "Cargando...");
            Swal.showLoading();
            const result = await request("/api/questionnaire/public/"+slug, {});
            Swal.close()
            if(result.data){
                setFormData(result.data);
            }else{
                Swal.fire("Cuestionario", "Error al cargar el cuestionario", "error");
            }
        }catch(e:unknown){

            Swal.fire("Cuestionario", "Error al cargar", "error");
        }
    }

    const signin = () => {
        if(sigCanvas.isEmpty()){
            Swal.fire("Cuestionario", "Es necesario ingresar una firma","error");
        }else{
            setDisableButton(true);
            Swal.fire("Cuestionario", "Guardando datos..");
            Swal.showLoading();
            const sendData = {...finalData,...userData};
            const blob = dataURItoBlob(sigCanvas.toDataURL('image/png'));
            const currentDate = new Date().getTime();
            const filename = "firma_"+currentDate+".png";
            const resultFile = new File([blob],filename);
            const data = new FormData();
            data.append('questionnaire', formData._id ? formData._id : '');
            for(const field of Object.keys(sendData) ){
                data.append(`fields[${field}]`, sendData[field]);
            }
            data.append("files", resultFile);
            let options = {
                method: 'POST',
                file:true,
                body: data
            };
            request(`/api/questionnaire/answer`, options)
            .then((result:ResponseResult) => {
                Swal.close();
                if(result.error){
                    setModalContent({
                        title: 'Cuestionario',
                        body: 'Ocurrió un error al registrar las respuestas.',
                        error: result.error.message,
                        show: true,
                    });
                }else{
                    endSurvey();                  
                }
                setDisableButton(false);
            })
        }
    }

    const dataURItoBlob = (dataURI:string) => {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    const onChange = (e:any) => {
        let data = {...userData};
        let targetName = e.target.name;
        const element = document.getElementById( e.target.id ) as HTMLInputElement;
        const validation =  element ? element.getAttribute('data-validate') : null;
        if(e.target.value && e.target.value != ''){
            let value = e.target.value;      
            let redirect = document.getElementById(e.target.id)?.getAttribute('data-redirect');
            if(redirect){
                setRedirect( Number(redirect) );
            }
            if( element.type == 'checkbox' ){
                let checkboxes = document.getElementsByName( e.target.name ) as NodeListOf<HTMLInputElement>;
                const checkboxesChecked = [];
                for (let i=0; i<checkboxes.length; i++) {
                    if (checkboxes[i].checked) {
                        checkboxesChecked.push(checkboxes[i].value);
                    }
                }
                data[targetName] = checkboxesChecked;

            }else if(validation){
                if(validation == 'email'){
                    value = value.toLowerCase().trim();
                }else if(validation == 'phone'){
                    value = value.toLowerCase().trim().replace(/\ /g,'').substring(0,10);
                }else if(validation == 'curp'){
                    value = e.target.value.toUpperCase().trim();
                }
                if(element){                        
                    element.value = value;
                }
                data[targetName] = value;
            }else{
                data[targetName] = value;               
            }            
        }else{
            delete data[targetName];
        }
        setData({...data});
        let storagedData = localStorage.data ? JSON.parse(localStorage.data) : {};
        if(!storagedData[currentSection]){
            storagedData[currentSection] = {};
        }
        storagedData[currentSection] = data;
        localStorage.data = JSON.stringify(storagedData);
    }

    const validateCurp = (text:string) => {
        const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
        let flag = text.match(regex);
        return flag;
    }

    function removeTags(str:string) {
        if ((str===null) || (str===''))
            return '';
        else
            str = str.toString();
        return str.replace( /(<([^>]+)>)/ig, '');
    }

    function shortText(str:string){
        return str.substring(0,40) + "...";
    }


    const nextSection = () => {
        if(formData.sections){
            let count_keys = 0;
            let required_keys = 0;
            let curp_count = 0;
            let curp_correct = 0;
            let missing_fields = '<ul class="error-list">';
            for(const question of formData.sections[currentSection].questions){
                console.log(question, checkDependency(question));
                if(!question.optional && checkDependency(question)){
                    required_keys++;
                    if( userData[ question.name ] ){
                        count_keys++;
                    }else{
                        missing_fields += '<li>'+shortText(removeTags(question.title))+'</li>'
                    }
                }
                if(question.type == 'curp'){
                    curp_count++;
                    if( validateCurp( userData[question.name] ? userData[question.name] : '' ) ){
                        curp_correct++;
                    }
                }
            }
            missing_fields += '</ul>'
            if( curp_count != curp_correct ){
                Swal.fire("Formulario", "La CURP ingresada es incorrecta", "error");
            }else if(required_keys == count_keys){
                setFinalData({...finalData,...userData});
                const localData = JSON.parse(localStorage.data);
                let index:any = 0;
                if(formData.sections && formData.sections[currentSection].redirect){
                    index = formData.sections && formData.sections[currentSection] && formData.sections[currentSection].redirect ? formData.sections[currentSection].redirect : currentSection + 1;
                }else{
                    const lastQuestion:Question = formData.sections[currentSection].questions[ formData.sections[currentSection].questions.length - 1 ];
                    if(lastQuestion.options){
                        const selectedOption:any = lastQuestion.options.filter((option:any)=>{
                            return option.value === userData[lastQuestion.name ? lastQuestion.name : ''];
                        });
                        index = selectedOption && selectedOption[0] && selectedOption[0].redirect ? selectedOption[0].redirect : currentSection + 1 ;
                    }
                }
                if(currentSection == (formData.sections.length - 1) || index == -1){
                    signin();
                }else{
                    if(index && localData[index]){
                        setData(localData[index]);
                    }else{
                        setData({});
                    }
                    let history = sectionHistory;
                    history.push(currentSection);
                    setSectionHistory(history);
                    setCurrentSection( index );
                    setRedirect(-2);
                    setValidated(false);
                    if(document.getElementById("formId")){
                        //(document.getElementById("formId") as HTMLElement).scrollIntoView();
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0;
                    }       
                    if( currentSection + 1 == ( formData.sections.length - 1) || (index && formData.sections[index].redirect == -1) ){
                        setLastStep(true);
                    }
                }
            }else{
                setValidated(true);
                Swal.fire({
                    title: 'Error formulario',
                    html: 'Hay campos sin completar que son requeridos. Requeridos: '+required_keys+', Ingresados: '+count_keys+'.<br/><br/> Campos faltantes<br/>'+missing_fields,
                    icon: 'error'
                });
            }
        }
    }
    
    const prevSection = () => {
        setRedirect( currentSection )
        let localData = JSON.parse(localStorage.data);
        let history = sectionHistory;
        setData(localData[ history[ history.length - 1 ] ]); 
        setCurrentSection( history[ history.length - 1 ] );        
        history.pop();
        setSectionHistory(history);
        setLastStep(false);
        if(document.getElementById("formId")){
            //(document.getElementById("formId") as HTMLElement).scrollIntoView();
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
        }
    }

    const endSurvey = () => {
        Swal.fire({
            title: 'Cuestionario',
            text: 'Tus respuestas se han guardado con éxito. Presiona el botón de esta ventana para completar el proceso.',
            icon: 'success'            
        }).then(()=>{
            delete localStorage.data;
            delete localStorage.code;
            window.location.reload();
        })
    }

    const checkDependency = (question:Question) => {
        console.log(1);
        if(!question.hide){
            return true;
        }else{
            let flag = true;
            for(const item of question.hide){
                const question_name = Object.keys(item)[0];
                console.log(userData[question_name], item[question_name])
                if( userData[question_name]){
                    if(item[question_name].includes( Number(userData[ question_name ]) ) ){
                        flag = false;
                        //delete userData[ question_name ];
                        //delete finalData[ question_name ];
                    }
                }else{
                    if( finalData[ question_name ] && item[question_name].includes( Number(finalData[ question_name ]) ) ){
                        flag = false;
                        //delete finalData[ question_name ];
                    }
                }
            }
            return flag;
        }
    }

    return (
        <div className='answer-form content preview'>   
            <div className="logos sticky-top">
                <Row>
                    <Col xs={12} md={{offset:2, span:8}} className="p-0 mt-3 mb-3 p-relative">
                        <div className="overlay">
                            <div className="d-none d-sm-none d-md-block">
                                <Row className="align-items-center">
                                    <Col xs={3} md={{span:3}}>
                                        <img src={Logo1} className="logo-1"></img>
                                    </Col>
                                    <Col xs={4} md={{span:5}}>                                
                                        <h2>Encuesta institucional<br/>FASP 2022</h2>
                                    </Col>
                                    <Col xs={5} md={{span:4}}>
                                        <img src={Logo2} className="logo-2"></img>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-block d-md-none d-lg-none">
                                <Row className="align-items-center">
                                    <Col xs={{span:3,offset:1}} md={{span:2}}>
                                        <img src={Logo1} className="logo-1"></img>
                                    </Col>
                                    <Col xs={{span:6, offset:2}} md={{span:3}}>
                                        <img src={Logo2} className="logo-2"></img>
                                    </Col>
                                    <Col xs={12} md={{span:7}}>                                
                                        <h2 className="mt-5">Encuesta institucional<br/>FASP 2022</h2>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div> 
            {
                firstStep ? (
                    <Row>
                        <Col xs={12} md={{span:8, offset:2}}>
                            <div className="introduction">
                                <div className="pt-3 pb-3">
                                    <div dangerouslySetInnerHTML={{__html: formData.introduction}}></div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col xs={12} md={4}>
                                        <Button className="mt-5 w-100" variant="primary" onClick={() => setFirstStep(false)}>Comenzar</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <div className="form-content">
                        <Row>
                            <Col xs={12} md={{span:8, offset:2}}>
                                <Form id="formId" noValidate validated={validated}>
                                    {
                                        (formData.sections && formData.sections[currentSection] && formData.sections[currentSection].section) && (
                                            <div className="section-header">
                                                <h3 className="mb-0">{ formData.sections[currentSection].section }</h3>
                                            </div>
                                        )
                                    }
                                    {
                                        (formData.sections && formData.sections[currentSection] && formData.sections[currentSection].questions[0] && formData.sections[currentSection].questions[0].sectionTitle) && (
                                            <div className="section-header">
                                                <p className="mb-0">{ formData.sections[currentSection].questions[0].sectionTitle }</p>
                                            </div>
                                        )
                                    }
                                        {
                                            formData.sections && formData.sections[currentSection] && formData.sections[currentSection].questions.map((item:Question,index:number)=>(
                                                <>
                                                    { checkDependency( item ) && (
                                                        <Row className="justify-content-center" key={item.title}>
                                                            <Col xs={12} md={12} className="p-0">
                                                                <Card className="mt-3" id={"card-"+item.name}>
                                                                    <Card.Body>
                                                                        <Row className="align-items-start">
                                                                            <Col key={currentSection+'-'+index} xs={12} md={12}>
                                                                                {
                                                                                    (item.type === 'text' || item.type === 'email' || item.type === 'phone' || item.type === 'curp') && (
                                                                                        <Input onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></Input>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.type == 'number' && (
                                                                                        <InputNumber onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></InputNumber>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.type == 'date' && (
                                                                                        <InputDate onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></InputDate>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.type == 'select' && (
                                                                                        <Select onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></Select>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.type == 'radio' && (
                                                                                        <Radio onChange={onChange} item={item} value={userData[item.name ? item.name : '']}></Radio>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.type == 'checkbox' && (
                                                                                        <Checkbox onChange={onChange} item={{
                                                                                            name: item.name,
                                                                                            title: item.title,
                                                                                            options: item.options,
                                                                                        }} value={userData[item.name ? item.name : '']}></Checkbox>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    item.type == 'description' && (
                                                                                        <Lecture item={item}></Lecture>
                                                                                    )
                                                                                }
                                                                            </Col>
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Card>
                                                            </Col>
                                                        </Row>
                                                    )
                                                    }
                                                </>
                                                ))
                                            }
                                            {
                                                showLastStep && (
                                                    <div className="mb-2 mt-5">
                                                        <Row>
                                                            <Col xs={12} md={{span:8,offset:2}}>
                                                                <p>Firma:</p>
                                                                <SignatureCanvas 
                                                                penColor='black' 
                                                                canvasProps={{height:400, className: 'sigCanvas'}} 
                                                                ref={(ref) => { sigCanvas = ref }}/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} md={{span:8,offset:2}}>
                                                                <button type="button" className="btn btn-danger" onClick={()=>{sigCanvas.clear()}}>Borrar firma</button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            }
                                            <Row className="justify-content-center">
                                                <Col xs={12} md={12}>
                                                    <div className="d-flex justify-content-end mt-3">
                                                        {
                                                            currentSection > 0 && (
                                                                <button className="btn btn-primary me-3" type="button" onClick={prevSection}>Regresar</button>
                                                            )
                                                        }
                                                        {
                                                            (!showLastStep && redirectTo != -1) ? (
                                                                <button className="btn btn-primary" type="button" onClick={nextSection}>Siguiente</button>
                                                            ) : (
                                                                <button className="btn btn-primary" type="button" onClick={nextSection} disabled={disableButton}>Enviar</button>
                                                            )
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                </Form>
                            </Col>
                        </Row>        
                    </div>
                ) 
            }
            <ModalError content={modalContent} handleClose={ ()  => { setModalContent({
                error: '',
                body: '',
                title: '',
                show: false
            }) }}></ModalError> 

        </div>
    );
}


export default QuestionnaireAnswer;
