import { Modal, Button, Row, Col } from 'react-bootstrap';
import { FaTimesCircle } from 'react-icons/fa';
import { useState } from 'react';

type Modal = {
    show:boolean;
    error: string;
    title: string;
    body: string;
}

function ModalError({content, handleClose}:{content:Modal, handleClose:any}){

    const [showError, setError] = useState(false);

    return (
        <Modal show={content.show} onHide={handleClose} className="error-modal">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={{span:6, offset:3}} md={{span:4, offset:4}}>
                        <FaTimesCircle className='error-icon'></FaTimesCircle>
                    </Col>
                </Row>
                <p className='text-center error-title mt-3'><b>{content.title}</b></p>
                <p className='text-center error-text mt-3'>{content.body}</p>
                {
                    showError && (
                        <div className="error-container">
                            <p className="error-text">{ content.error }</p>
                        </div>
                    )
                }
                <Row>
                    <Col xs={12} md={{span:8, offset:2}}>
                        <Button className='w-100 mt-3' variant="secondary" onClick={()=>{setError(!showError)}}>
                            { showError ? 'Ocultar' : 'Ver detalle'}
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalError;
