import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Button, Table} from 'react-bootstrap';
import { ResponseResult, Session } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function SessionList(){

    const [sessions, setSessions] = useState<Session[]>([]);
    useEffect(()=>{
        getSessions();
    },[])

    const getSessions = async () =>{
        try{
            Swal.fire("Sessiones", "Cargando...");
            Swal.showLoading();
            const result = await request("/api/session",{});
            Swal.close();
            if(result.data){
                setSessions(result.data);
            }
        }catch(e:unknown){
            Swal.fire("Sesiones", "Ocurrió un error al recuperar las sesiones", "error");
        }
    }

    const toggle = async (session:Session) => {
        Swal.fire("Cambiar estatus", "Cargando...");
        Swal.showLoading();
        const response = await request('/api/session/toggle/'+session._id,{
            method: 'PUT',
            body: JSON.stringify({
                status: !session.status
            })
        });
        if(response.data){
            getSessions();
        }else{
            Swal.fire("Eliminar alumno", "Ocurrió un error al actualizar el usuario", "error");
        }        
    }

    return (
        <div id="upload"  className="content">
            <Row>
                <Col xs={24} md={{span:8,offset:2}}>
                    <Card className="mt-5">
                        <Card.Body>
                            <h3>Sesiones</h3>                        
                            <Table className='mt-2'>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Contraseña</th>
                                        <th>Estatus</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sessions.map((session:Session,key:number)=>(
                                            <tr key={key}>
                                                <td>{ session.name }</td>
                                                <td>{ session.password }</td>
                                                <td>{ session.status ? 'Activo' : 'Inactivo' }</td>
                                                <td>
                                                    <Button className='btn btn-danger' onClick={()=>toggle(session)}>Cambiar estatus</Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default SessionList;