import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Home from "./pages/Home";
import QuestionnaireList from "./pages/questionnaires/QuestionnaireList";
import QuestionnaireNew from "./pages/questionnaires/QuestionnaireNew";
import QuestionnaireEdit from "./pages/questionnaires/QuestionnaireEdit";
import QuestionnaireAnswer from "./pages/questionnaires/QuestionnaireAnswer";
import UserList from './pages/users/UserList';
import UserNew from './pages/users/UserNew';
import ConfirmPassword from './pages/auth/ConfirmPassword';
import UserEdit from './pages/users/UserEdit'
import UsersUpload from './pages/users/UsersUpload';
import StudentsList from "./pages/students/StudentsList";
import StudentQuestionnaireSign from "./pages/students/StudentQuestionnaireSign";
import SessionList from "./pages/sessions/SessionList";
import Header from './components/Header';
import Footer from './components/Footer';
import "./App.scss"
import {Container} from 'react-bootstrap';
import ProtectedRoute from "./services/ProtectedRoute";
import SessionContext, { initialSession } from './context/Session';
import { getSession } from './services/Session.service';
import { useState, useEffect } from 'react';

function App() {

  const [session, setSession] = useState({ loading: true, data: initialSession });

  useEffect(() => {
    async function getSessionAsync(){
      const { data } = await getSession();
      if(data){
        console.log(1);
        setSession({ loading: false, data });
      }else{
        console.log(2);
        setSession({ loading: false, data: initialSession })
      }
    }
    getSessionAsync();
  }, []);

  return (
    <SessionContext.Provider value={session.data}>
      <Container fluid className="p-0">
        <Header></Header>
        <BrowserRouter>
          {
            !session.loading  && (
            <Routes>
                <Route path="/" element={<StudentQuestionnaireSign />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="confirmation/:token" element={<ConfirmPassword />} />
                <Route path="responder/:slug" element={<QuestionnaireAnswer />} />
                <Route path="questionnaires/edit/:slug" element={ <ProtectedRoute><QuestionnaireEdit /></ProtectedRoute>} />
                <Route path="questionnaires/new" element={<ProtectedRoute><QuestionnaireNew /></ProtectedRoute>} />
                <Route path="questionnaires" element={<ProtectedRoute><QuestionnaireList /></ProtectedRoute>} />
                <Route path="users/edit/:id" element={<ProtectedRoute><UserEdit /></ProtectedRoute>} />
                <Route path="students" element={<ProtectedRoute><StudentsList /></ProtectedRoute>} />
                <Route path="sessions" element={<ProtectedRoute><SessionList /></ProtectedRoute>} />
                <Route path="users/new" element={<ProtectedRoute><UserNew /></ProtectedRoute>} />
                <Route path="users/upload" element={<ProtectedRoute><UsersUpload /></ProtectedRoute>} />
                <Route path="users" element={<ProtectedRoute><UserList /></ProtectedRoute>} />
            </Routes>
            )
          }
        </BrowserRouter>
      </Container>
    </SessionContext.Provider>
  );
}

export default App;
