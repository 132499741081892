import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { request } from '../../services/Request';
import '../../styles/form.scss';
import Swal from 'sweetalert2';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Logo1 from '../../assets/logo_gobierno.png';
import Logo2 from '../../assets/logo_pec.jpg';

function StudentQuestionnaireSign(){

    const [userData, setUserData]:any = useState({
        name: '',
        password: ''
    });

    const search = async() => {
        try{
            if(!userData.name || !userData.password){
                Swal.fire("Registro","Es necesaio ingresar un nombre y contraseña","error");
            }else{
                const result = await request("/api/session/find",{
                    method: 'POST',
                    body: JSON.stringify({
                        name: userData.name,
                        password: userData.password
                    })
                });
                if(result.data){
                    window.location.href="/responder/ENCUESTA-INSTITUCIONAL-FASP-2022?token="+result.data.token;
                }else if(result.error.status == 420){
                    Swal.fire("Registro", "No hay un usuario registrado con estas credenciales.", "error");
                }else{
                    Swal.fire("Registro","Ocurrió un error al buscar el usuario. Error 2.","error");
                }
            }
        }catch(e:unknown){
            Swal.fire("Registro","Ocurrió un error al buscar el usuario. Error 1.","error")
        }
    } 

    const onChange = (e:any) => {
        let data = {...userData};
        data[e.target.name] = e.target.value.trim();
        setUserData(data);
    }

    return (
        <div className='answer-form content preview'> 
            <div className="logos sticky-top">
                <Row>
                    <Col xs={12} md={{offset:2, span:8}} className="p-0 mt-3 mb-3 p-relative">
                        <div className="overlay">
                            <div className="d-none d-sm-none d-md-block">
                                <Row className="align-items-center">
                                    <Col xs={3} md={{span:3}}>
                                        <img src={Logo1} className="logo-1"></img>
                                    </Col>
                                    <Col xs={4} md={{span:5}}>                                
                                        <h2>Encuesta institucional<br/>FASP 2022</h2>
                                    </Col>
                                    <Col xs={5} md={{span:4}}>
                                        <img src={Logo2} className="logo-2"></img>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-block d-md-none d-lg-none">
                                <Row className="align-items-center">
                                    <Col xs={{span:3,offset:1}} md={{span:2}}>
                                        <img src={Logo1} className="logo-1"></img>
                                    </Col>
                                    <Col xs={{span:6, offset:2}} md={{span:3}}>
                                        <img src={Logo2} className="logo-2"></img>
                                    </Col>
                                    <Col xs={12} md={{span:7}}>                                
                                        <h2 className="mt-5">Encuesta institucional<br/>FASP 2022</h2>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div> 
            <Row>
                <Col xs={12} md={{offset:2,span:8}}>
                    <div>
                        <Card className="mt-5">
                            <Card.Body>
                                <p>Para responder la encuesta, es necesario ingresar un usuario y contraseña.</p>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nombre de usuario</Form.Label>
                                    <Form.Control type='text' name="name" onChange={onChange}></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control type='password' name="password" onChange={onChange}></Form.Control>
                                </Form.Group>
                                <Row className="mt-3 justify-content-center">
                                    <Col xs={12} md={4}>
                                        <Button className="w-100" onClick={search}>Ingresar</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>  
                </Col>
            </Row>
        </div>
    );
}

export default StudentQuestionnaireSign;