import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { User, ResponseResult } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function UserNew(){

    const [userData, setUserData] = useState<Partial<User>>({});

    const submit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if( !userData['name']
        || !userData['email']){
            Swal.fire("Usuario", "Es necesario completar todos los campos", "error");
        }else{
            Swal.fire("Usuario", "Guardando registro...");
            Swal.showLoading();
            request("/api/user",{
                method: 'POST',
                body: JSON.stringify(userData)
            })
            .then((result:ResponseResult)=>{
                Swal.close();
                if(result.data){
                    window.location.href="/users";
                }else if(result.error.status == 420){
                    Swal.fire("Usuario", "El usuario ya existe", "error");
                }else{
                    Swal.fire("Usuario", "Ocurrió un error al guardar el usuario", "error");
                }
            })
        }
    }

    const onChange = (e: any)=>{
        let data = userData;
        data[e.target.name] = e.target.value;
        setUserData(data);
    }

    return (
        <div id="users-form" className="content">
            <Row>
                <Col xs={12} md={{span:8,offset:2}}>
                    <h2 className="mt-5 mb-5">Nuevo usuario</h2>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={submit}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" placeholder="" name="name" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Correo electrónico</Form.Label>
                                    <Form.Control type="email" placeholder="" name="email" onChange={onChange}/>
                                </Form.Group>
                                <Form.Group className="mb-3">                                                                    
                                    <Form.Label>Tipo de usuario</Form.Label>
                                    <Form.Select aria-label="Tipo de pregunta" name="role" onChange={onChange}>
                                        <option></option>
                                        <option value="1">Administrador</option>
                                        <option value="2">Profesor</option>
                                    </Form.Select>
                                </Form.Group> 
                                <Row className="mt-5">
                                    <Col xs={12} md={4}>
                                        <Button variant="primary" type="submit" className="w-100">
                                            Enviar invitación
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <a className="ml-3 btn btn-danger w-100" href="/users">
                                            Cancelar
                                        </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default UserNew;