import { useEffect, useCallback } from 'react';
import { request } from '../services/Request';
import debounce from "lodash.debounce";
import { Questionnaire } from '../types';
import Swal from 'sweetalert2';

function Autosave({ questionnaire, setQuestionnaire }:{questionnaire:Partial<Questionnaire>, setQuestionnaire:any}) {
    const debouncedSave = useCallback(
      debounce(async (questionnaire:Partial<Questionnaire>) => {
        let result:any = {};
        if(questionnaire && questionnaire.name){
            if(questionnaire._id){
                result = await request("/api/questionnaire/"+questionnaire._id, {
                    method:'PUT',
                    body: JSON.stringify(questionnaire)
                });
            }else{
                result = await request("/api/questionnaire", {
                    method:'POST',
                    body: JSON.stringify(questionnaire)
                });
            }
            if(result.data){
                setQuestionnaire(result.data);
            }else{
                Swal.fire("Autoguardado", "No se puedo gaurdar automáticamente", "error");
            }
        }
      }, 1000 * 60),
      [],
    );
    
    // The magic useEffect hook. This runs only when `experimentData.name` changes.
    // We could add more properties, should we want to listen for their changes.
    useEffect(() => {
      if (questionnaire) {
        debouncedSave(questionnaire);
      }
      // debouncedSave is wrapped in a useCallback with an empty dependency list,
      // thus it will not change and in turn will not re-trigger this effect.
    }, [questionnaire, debouncedSave]);
    // Do not display anything on the screen.
    return null;
}

export default Autosave;