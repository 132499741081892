import React, {useState, useEffect} from 'react';
import {Row, Col, Form, Button, Card} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { request } from '../../services/Request';
import { Questionnaire, Section, Question, Option, RouteParam } from '../../types';
import slugify from 'slugify';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../styles/questionnaires.scss';
import Image from '../../assets/Arrow 2.png';
import QuestionnairePreview from './QuestionnairePreview';
import Check from '../../assets/Component 6.png';
import Autosave from '../../components/AutoSave';

function QuestionnaireEdit(){

    const [questionnaire, setQuestionnaire] = useState<Partial<Questionnaire>>({
    });
    const [currentQuestion, setQuestion] = useState(1);
    const [selectedMenu, setSelectedMenu] = useState('Comenzar');
    const {slug} = useParams<RouteParam>();
    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          [{ script: "super" }, { script: "sub" }],
          ['link', 'image', 'formula', 'strike', 'align'],
          ['clean']
        ],
      };
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'script',
        'link', 'image', 'formula',  'strike', 'align'
    ];

    useEffect(()=>{
        getQuestionnaire();
    }, []);

    const getQuestionnaire = async () => {
        try{
            Swal.fire("Editar cuestionario", "Cargando...");
            Swal.showLoading();
            const result = await request('/api/questionnaire/'+slug,{});
            Swal.close();
            if(result.data){
                console.log(result.data);
                setQuestionnaire({ ...questionnaire, ...result.data });
                setQuestionnaire({ ...questionnaire, ...result.data });
                setQuestion( result.data.last_question );
                
            }else{
                Swal.fire("Editar cuestionario", "Ocurrió un error", "error");    
            }
        }catch(e:unknown){
            Swal.fire("Editar cuestionario", "Ocurrió un error", "error");
        }
    }

    const onSubmit = async ()=>{
        try{
            Swal.fire("Nuevo cuestionario", "Guardando...");
            Swal.showLoading();
            const data = questionnaire;
            data["last_question"] = currentQuestion;
            const result = await request("/api/questionnaire/"+questionnaire._id,{
                method: 'PUT',
                body: JSON.stringify(data)
            });
            Swal.close();
            if(result.data){
                setSelectedMenu('Guardar');
            }else{
                Swal.fire("Nuevo cuestionario","Ocurrió un error al guardar","error");
            }
        }catch(e:unknown){
            Swal.fire("Nuevo cuestionario","Ocurrió un error al guardar","error");
        }
    }

    const onChange = (e:any) => {
        const data = questionnaire;
        data[e.target.name] = e.target.value;
        if(e.target.name === 'name'){
            data['slug'] = slugify(e.target.value);
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const onChangeSection = (e:any, keySection:number) => {
        const data = questionnaire;
        if(data.sections){
            data.sections[keySection][e.target.name] = e.target.value;
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const onChangeQuestion = (e:any, keySection:number, keyQuestion:number) => {
        const data = questionnaire;
        if(data.sections){
            if(e.target.name == 'optional'){    
                data.sections[keySection].questions[keyQuestion][e.target.name] = e.target.checked;
            }else{
                data.sections[keySection].questions[keyQuestion][e.target.name] = e.target.value;
            }
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const onChangeOption = (e:any, keySection:number, keyQuestion:number, keyOption:number) => {
        const data = questionnaire;
        if(data.sections){
            data.sections[keySection].questions[keyQuestion].options[keyOption][e.target.name] = e.target.value;
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const addSection = () => {
        const data = questionnaire;
        if(!data.sections){
            data.sections = [];
        }
        data.sections.push({
            id: String( Math.random() ),
            section: '',
            validate: 0,
            questions: []
        });
        setQuestionnaire({...questionnaire, ...data});
    }

    const addQuestion = (keySection:number) => {
        const data = questionnaire;
        if(data.sections){
            data.sections[keySection].questions.push({
                id: String( Math.random() ),
                title: '',
                type: '',
                optional: false,
                name: "question_"+currentQuestion,
                complement: '',
                options: []
            });
            setQuestion( currentQuestion+1 )
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const addOption = (keySection:number, keyQuestion:number) => {
        const data = questionnaire;
        if(data.sections && data.sections[keySection]){
            data.sections[keySection].questions[keyQuestion].options.push({
                id: String( Math.random() ),
                label: '',
                value: ''
            });
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const removeSection = (keySection:number) => {
        const data = questionnaire;
        if(data.sections){
            data.sections.splice(keySection,1);
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const removeQuestion = (keySection:number, keyQuestion:number) => {
        const data = questionnaire;
        if(data.sections){
            data.sections[keySection].questions.splice(keyQuestion,1);
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const removeOption = (keySection:number, keyQuestion:number, keyOption:number) => {
        const data = questionnaire;
        if(data.sections){
            data.sections[keySection].questions[keyQuestion].options.splice(keyOption,1);
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const setIntroduction = (e:any) => {
        const data = questionnaire;
        data['introduction'] = e;
        setQuestionnaire({...questionnaire, ...data});
    }

    const setIntroductionQuestion = (e:any, keySection: number, keyQuestion: number) => {
        const data = questionnaire;
        if(data.sections){
            data.sections[keySection].questions[keyQuestion].title = e;
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const saveClipboard = async () => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText( window.location.hostname+"/responder/"+questionnaire.slug);
        } else {
            return document.execCommand('copy', true, window.location.hostname+"/responder/"+questionnaire.slug);
        }
    }

    const setLabel = (e:any, keySection:number, keyQuestion:number, keyOption:number) => {
        const data = questionnaire;
        if(data.sections){
            data.sections[keySection].questions[keyQuestion].options[keyOption].label = e;
        }
        setQuestionnaire({...questionnaire, ...data});
    }

    const copyQuestion = (section:number, question:number)=>{
        const data = questionnaire;
        console.log(section, question);
        if(data.sections){
            const element =  JSON.parse(JSON.stringify(data.sections[section].questions[question]));
            element['id'] = String( Math.random() );
            element['_id'] = undefined;
            element['name'] = "question_"+currentQuestion;
            data.sections[section].questions.push(element);
            console.log(data[section]);
        }
        setQuestion( currentQuestion+1 );
        setQuestionnaire({...questionnaire, ...data});
    }

    const save = async ()=>{
        try{
            Swal.fire("Nuevo cuestionario", "Guardando...");
            Swal.showLoading();
            const data = questionnaire;
            data["last_question"] = currentQuestion;
            let result:any ={};
            if(questionnaire._id){
                result = await request("/api/questionnaire/"+questionnaire._id,{
                    method: 'PUT',
                    body: JSON.stringify(data)
                });
            }else{
                result = await request("/api/questionnaire",{
                    method: 'POST',
                    body: JSON.stringify(data)
                });
            }
            Swal.close();
            if(result.data){
                Swal.fire("Guardar", "Cambios guardados", "success");
                setQuestionnaire(result.data)
            }else{
                Swal.fire("Nuevo cuestionario","Ocurrió un error al guardar","error");
            }
        }catch(e:unknown){
            Swal.fire("Nuevo cuestionario","Ocurrió un error al guardar","error");
        }
    }

    return (
        <div className="form">
            <Autosave questionnaire={questionnaire} setQuestionnaire={setQuestionnaire}/>
            <div className="submenu">
                <Row className="align-items-center">
                    <Col xs={2} md={2}>
                       <p className={`${ selectedMenu == 'Comenzar' ? 'active' : '' }`} onClick={ () => setSelectedMenu('Comenzar')}>Comenzar</p> 
                    </Col>
                    <Col xs={3} md={2}>
                        <img src={Image}></img>
                    </Col>
                    <Col xs={2} md={2}>
                        <p className={`${ selectedMenu == 'Vista previa' ? 'active' : '' }`} onClick={ () => setSelectedMenu('Vista previa')}>Vista previa</p>
                    </Col>
                    <Col xs={3} md={2}>
                        <img src={Image}></img>
                    </Col>
                    <Col xs={2} md={2}>
                        <p className={`${ selectedMenu == 'Guardar' ? 'active' : '' }`} onClick={ () => setSelectedMenu('Guardar')}>Guardar</p>
                    </Col>
                </Row>
            </div>
            <div className="content">
                <Row>
                    <Col xs={12} md={{span:10, offset:1}}>
                        {
                            selectedMenu == 'Comenzar' && (
                                <Form >
                                    <h2>
                                        Escribe las preguntas y respuestas en los apartados
                                    </h2>
                                    <Card>
                                        <Card.Body>
                                                <Form.Group className="mb-3" controlId="name">
                                                    <Form.Label>Título del cuestionario</Form.Label>
                                                    <Form.Control type="text" name="name" onChange={onChange} defaultValue={questionnaire.name}/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="duration">
                                                    <Form.Label>Duración del cuestionario (minutos)</Form.Label>
                                                    <Form.Control type="number" name="duration" onChange={onChange} defaultValue={questionnaire.duration}/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="limit_date">
                                                    <Form.Label>Fecha límite</Form.Label>
                                                    <Form.Control type="date" name="limit_date" onChange={onChange} value={questionnaire.limit_date ? questionnaire.limit_date.split('T')[0]  : ' '}/>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="slug">
                                                    <Form.Label>URL</Form.Label>
                                                    <Form.Control type="text" disabled defaultValue={questionnaire.slug}/>
                                                </Form.Group>     
                                                <Form.Label>Texto introductorio</Form.Label>
                                                <ReactQuill theme="snow" value={questionnaire.introduction || ''} onChange={(e:any)=>setIntroduction(e)} modules={modules} formats={formats}/>                                 
                                        </Card.Body>
                                    </Card>
                                    {
                                        questionnaire.sections && questionnaire.sections.map((item:Section, keySection:number)=>(
                                            <div key={(item.id ? item.id : item._id)} className="mt-3">
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>Sección { keySection +1 }</Card.Title>
                                                        <Form.Group className="mb-3" controlId={"section-"+(item.id ? item.id : item._id)}>
                                                            <Form.Label>Título de la sección</Form.Label>
                                                            <Form.Control type="text" name="section" onChange={(e:any) => onChangeSection(e, keySection)} defaultValue={item.section}/>
                                                        </Form.Group>                                            
                                                        {
                                                            item.questions.map((question:Question, keyQuestion:number)=>(
                                                                <div key={(question.id ? question.id : question._id)} className="ps-3">
                                                                    <hr className="mt-3 mb-3"></hr>
                                                                    <p><b>Pregunta { keyQuestion + 1 }</b></p>
                                                                    <Row className="align-items-center">
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Label>Pregunta</Form.Label>
                                                                            <ReactQuill id={"question-"+(item.id ? item.id : item._id)+"-"+(question.id ? question.id : question._id)} theme="snow" value={question.title || ""} onChange={(e:any)=>setIntroductionQuestion(e, keySection, keyQuestion)} modules={modules} formats={formats}/> 
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group className="mt-3 mb-3">                                                                    
                                                                                <Form.Label>Tipo de pregunta</Form.Label>
                                                                                <Form.Select aria-label="Tipo de pregunta" name="type" id={"type-"+(item.id ? item.id : item._id)+"-"+(question.id ? question.id : question._id)} onChange={(e:any) => onChangeQuestion(e, keySection, keyQuestion)}>
                                                                                    <option></option>
                                                                                    <option value="radio" selected={ question.type === 'radio' }>Opción múltiple</option>
                                                                                    <option value="text" selected={ question.type === 'text' }>Texto</option>
                                                                                    <option value="checkbox" selected={ question.type === 'checkbox' }>Selección múltiple</option>
                                                                                    <option value="description" selected={ question.type === 'description' }>Descripción</option>
                                                                                    <option value="number" selected={ question.type === 'number' }>Número</option>
                                                                                    <option value="date" selected={ question.type === 'date' }>Fecha</option>
                                                                                    <option value="email">Email</option>
                                                                                    <option value="phone">Teléfono</option>
                                                                                    <option value="curp">CURP</option>
                                                                                </Form.Select>
                                                                            </Form.Group> 
                                                                        </Col>
                                                                        <Col xs={12} md={2}>
                                                                            <Form.Check 
                                                                                type={'checkbox'}
                                                                                id={"optional-"+(item.id ? item.id : item._id)+"-"+(question.id ? question.id : question._id)}
                                                                                label={`Opcional?`}
                                                                                name="optional"
                                                                                defaultChecked={ question.optional }
                                                                                onChange={(e:any) => onChangeQuestion(e, keySection, keyQuestion)}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={12} md={2}>
                                                                            <Button type='button' variant="secondary" className="mt-3 mb-3 float-end" onClick={() => copyQuestion(keySection, keyQuestion)}> Copiar Pregunta</Button>
                                                                        </Col>
                                                                        <Col xs={12} md={2}>
                                                                            <Button type='button' variant="danger" className="mt-3 mb-3 float-end" onClick={() => removeQuestion(keySection, keyQuestion)}> Eliminar Pregunta</Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Button type='button' variant="secondary" className="mt-3 mb-3" onClick={() => addOption(keySection, keyQuestion)}> Agregar opción</Button>
                                                                    {
                                                                        question.options.map((option:Option, keyOption:number)=>(
                                                                            <div key={option.id} className="ps-5">
                                                                                <hr className="mt-3 mb-3"></hr>
                                                                                <Row>
                                                                                    <Col xs={12} md={12}>        
                                                                                        <Form.Group className="mb-3" controlId={"option-"+(item.id ? item.id : item._id)+"-"+question.id+"-"+option.id}>
                                                                                            <Form.Label>Leyenda de la opción</Form.Label>
                                                                                            <ReactQuill id={"label-"+item.id+"-"+question.id} theme="snow" value={option.label || ""} onChange={(e:any)=>setLabel(e, keySection, keyQuestion, keyOption)} modules={modules} formats={formats}/> 
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col xs={12} md={3}>
                                                                                        <Form.Group className="mb-3" controlId={"value-"+(item.id ? item.id : item._id)+"-"+(question.id ? question.id : question._id)+"-"+option.id}>
                                                                                            <Form.Label>Valor de la opción</Form.Label>
                                                                                            <Form.Control type="text" name="value" defaultValue={option.value} onChange={(e:any) => onChangeOption(e, keySection, keyQuestion, keyOption)}/>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col xs={12} md={4}>
                                                                                        <Form.Group className="mb-3" controlId={"just-"+(item.id ? item.id : item._id)+"-"+(question.id ? question.id : question._id)+"-"+option.id}>
                                                                                            <Form.Label>Justificación</Form.Label>
                                                                                            <Form.Control as="textarea" name="justification" value={option.justification} onChange={(e:any) => onChangeOption(e, keySection, keyQuestion, keyOption)}/>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col xs={12} md={2}>
                                                                                        <Button type='button' variant="danger" className="mt-3 mb-3 float-end" onClick={() => removeOption(keySection, keyQuestion, keyOption)}> Eliminar Opción</Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                        <Row>
                                                            <Col xs={12} md={4}>
                                                                <Button type='button' variant="secondary" className="mt-3 mb-3" onClick={() => addQuestion(keySection)}> Agregar pregunta</Button>
                                                            </Col>
                                                            <Col xs={12} md={{span:4, offset:4}}>
                                                                <Button type='button' variant="danger" className="mt-3 mb-3 float-end" onClick={() => removeSection(keySection)}> Eliminar sección</Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        ))
                                    }
                                    <Button type='button' variant="secondary" className="mt-3 mb-3" onClick={addSection}> Agregar sección  de preguntas</Button>
                                    <Row className="justify-content-end">
                                        <Col xs={12} md={4}>
                                            <Button type='button' variant="secondary" className="mt-5 mb-3 w-100" onClick={save}>Guardar</Button>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Button type='button' variant="primary" className="mt-5 mb-3 w-100" onClick={ () => setSelectedMenu('Vista previa') }> Vista previa</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }
                        {
                            selectedMenu == 'Vista previa' && (
                                <div className={"preview"}>
                                    <Row className="align-items-center">
                                        <Col xs={12} md={3}>
                                            <Button type='button' className="w-100 btn-blue" onClick={ () => setSelectedMenu('Comenzar') }>Seguir editando</Button>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <h2 className="mb-0 title">Corrobora tu cuestionario</h2>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <QuestionnairePreview questionnaire={ questionnaire }></QuestionnairePreview>
                                    <hr/>
                                    <Row className="justify-content-end">
                                        <Col xs={12} md={4}>
                                            <Button type='button' variant="primary" className=" w-100" onClick={ () => onSubmit() }>Guardar</Button>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                        {
                            selectedMenu == 'Guardar' && (
                                <div className="saved">
                                    <h2>Tu cuestionario ha sido <b>guardado con éxito</b></h2>
                                    <h3>Ahora se encuentra en <b>Mis cuestionarios</b></h3>
                                    <Row className="mt-5 justify-content-center">
                                        <Col xs={6} md={2}>
                                            <img src={Check} className="w-100"/>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs={12} md={4}>
                                            <Button className="btn-dark-blue mt-5 w-100" onClick={saveClipboard}>Copiar link</Button>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default QuestionnaireEdit;