import '../styles/questionnaires.scss';
import { Questionnaire } from '../types';
import { Row, Col } from 'react-bootstrap';
import Image1 from '../assets/Icono formato.png';
import Edit from '../assets/Editar.png';
import Remove from '../assets/Eliminar.png';
import Download from '../assets/Descargar resultados.png';
import Share from '../assets/Compartir.png';
import Swal from 'sweetalert2';
import { request } from '../services/Request';

function QuestionnaireCard({questionnaire, callback}:{questionnaire:Questionnaire, callback:any}){

    const remove = (item: Questionnaire) => {
        Swal.fire({
            title: 'Eliminar cuestionario',
            text: `¿Estás seguro de eliminar el cuestionario ${item.name}?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        })
        .then(async (result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar cuestionario", "Realizando petición..");
                Swal.showLoading()
                const result = await request('/api/questionnaire/'+item._id,{
                    method: 'DELETE'
                });
                if(result.data){
                    Swal.fire("Eliminar cuestionario","El cuestionario se ha eliminado con exito","success");
                    callback();
                }else{
                    Swal.fire("Eliminar cuestionario","Ocurrió un error al realizar el proceso","error");
                }
            }
        })
    }

    const sendResults = (item: Questionnaire) => {
        Swal.fire({
            title: 'Enviar resultados',
            text: `¿Estás seguro de enviarte los resultados del cuestionario ${item.name} a tu correo?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        })
        .then(async (result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Enviar resultados", "Realizando petición..");
                Swal.showLoading()
                const result = await request('/api/questionnaire/results/'+item._id,{
                    method: 'GET'
                });
                if(result.data){
                    Swal.fire("Enviar resultados","La petición se realizó con éxito. En unos minutos estará llegando a su cuenta de correos.","success");
                }else{
                    Swal.fire("Enviar resultados","Ocurrió un error al realizar el proceso","error");
                }
            }
        })
    }

    return (
        <div className="questionnaire-card">
            <div className="d-block d-md-none d-lg-none">
                <div className="questionnaire-body">
                    <div className="questionnaire-title">
                        {
                            questionnaire.name
                        }
                    </div>
                    <div className="questionnaire-options">
                        <Row>
                            <Col xs={3}>
                                <a href={'/questionnaires/edit/'+questionnaire._id} title="Editar"><img className="indicator" src={Edit}></img></a>
                            </Col>
                            <Col xs={3}>
                                <a onClick={()=>remove(questionnaire)} title="Eliminar"><img className="indicator" src={Remove}></img></a>
                            </Col>
                            <Col xs={3}>
                                <a href={'/responder/'+questionnaire.slug} title="Compartir"><img className="indicator" src={Share}></img></a>
                            </Col>
                            <Col xs={3}>
                                <a onClick={()=>sendResults(questionnaire)} title="Enviar resultados a correo"><img className="indicator" src={Download}></img></a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="d-none d-sm-none d-md-block">
                <Row className="align-items-center">
                    <Col md={10}>
                        <a href={'/questionnaires/edit/'+questionnaire._id} title="Editar">
                            <img src={Image1} className="w-100"></img>
                        </a>
                    </Col>
                    <Col md={2} className="p-0">
                        <div className="buttons">
                            <a href={'/questionnaires/edit/'+questionnaire._id} title="Editar"><img src={Edit} className="icon"></img></a>
                            <a onClick={()=>remove(questionnaire)} title="Eliminar"><img src={Remove} className="icon"></img></a>
                            <a href={'/responder/'+questionnaire.slug} title="Compartir"><img src={Share} className="icon"></img></a>
                            <a onClick={()=>sendResults(questionnaire)} title="Enviar resultados a correo"><img src={Download} className="icon"></img></a>
                        </div>
                    </Col>
                    <Col>
                        <p className="ms-3">{questionnaire.name}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default QuestionnaireCard;