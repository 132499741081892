import React, {useState, useEffect} from 'react';
import { request } from '../services/Request';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import { ResponseResult } from '../types';
import '../styles/header.scss';

function Header(){

    const [isAuth, setAuth] = useState(false);
    const [role, setRole] = useState(0);

    useEffect(()=>{
        request('/api/auth/me',{
            noRedirect:true
        }).then((result:ResponseResult)=>{
            if(result.data){
                setAuth(true);
                setRole(result.data.role);
            }
        });
    }, []);

    const close = () => {
        delete localStorage.token;
        window.location.href = "/";
    }

    return (
        <React.Fragment>
            {
                isAuth && (
                <Navbar expand="lg" className="header" fixed={"top"}>
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Inicio</Nav.Link>
                                <>
                                    <Nav.Link href="/questionnaires">Mis cuestionarios</Nav.Link>
                                    {
                                        role == 1 && (
                                            <Nav.Link href="/users">Usuarios</Nav.Link>
                                        )
                                    }
                                    {
                                        role == 1 && (
                                            <Nav.Link href="/sessions">Sesiones</Nav.Link>
                                        )
                                    }
                                    <Nav.Link href="#" onClick={close}>Cerrar sesión</Nav.Link>
                                </>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            )
        }
        </React.Fragment>
    )
}

export default Header;